











































































































































































































































































































































































































































































import ViewModel from '@/views/service/AdminAdvantage.ts';
export default ViewModel;
